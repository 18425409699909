import {
  GeneratePresignedUrlDocument,
  GraphQLGeneratePresignedUrlInput,
  urqlClient,
} from "../../graphql";

const uploadImageFileToS3 = async (
  presignedUrl: string,
  acl: string,
  file: File,
) => {
  const blob = new Blob([file], { type: file.type });
  await fetch(presignedUrl, {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": blob.type,
      "x-amz-acl": acl,
    },
    body: blob,
  });

  // remove query parameters
  const urls = presignedUrl.split("?");
  return urls[0];
};

export const uploadImageFile = async (
  input: GraphQLGeneratePresignedUrlInput,
  file: File,
) => {
  const { data } = await urqlClient
    .mutation(GeneratePresignedUrlDocument, { input })
    .toPromise();
  if (!data) throw new Error("Failed to create presigned URL");
  const { presignedUrl, acl } = data.generatePresignedUrl;
  return await uploadImageFileToS3(presignedUrl, acl, file);
};
